<template>
    <div class="px-2 py-1 relative">
        {{ item[column].length }}
    </div>
</template>

<script>
export default {
    name: "base-column-count",
    props: {
        'column': {type: String},
        'item': {type: Object}
    }
}
</script>

<style scoped>

</style>