<template>
    <div class="w-full flex flex-col bg-gray-50 h-full relative">

        <autralis-success-comp :show="savedFlag"/>
        <autralis-error-comp :show="error" :text="error"/>

        <autralis-modal-comp :modal="modal"/>
        <autralis-loading-table-comp v-if="fetchingGroups.loading" :columns="columns"/>


        <div v-else class="flex flex-col w-full">
            <!--HEADER-->
            <div class="bg-gray-50 md:bg-white flex w-full p-6 sticky z-10" :style="'top:' + headerTop + 'px'">
                <div class="flex flex-1 w-full">
                    <!-- SMALL SCREENS (<1245px) -->
                    <div class="business-units-header-sm flex flex-col w-full">
                        <div class="flex justify-between w-full">
                            <!-- BUTTON ADD NEW -->
                            <div class="border-r border-gray-200 pr-4 mr-4">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus mr-1"/><span>Add</span>
                                </button>
                            </div>

                            <!-- OTHER BUTTONS DROPDOWN -->
                            <div class="flex items-center justify-center relative">
                                <i class="fal fa-ellipsis-h-alt text-2xl text-gray-500 hover:text-blue-700 transition ease-in-out duration-150 cursor-pointer" @click="menuOpen = !menuOpen"/>
                                <div v-if="menuOpen" class="bg-white absolute right-0 z-10 rounded-md shadow-lg p-4 dropdown-nav" style="">
                                    <div v-if="businessUnitGroups && businessUnitGroups.length > 0" class="flex items-center mb-4 cursor-pointer text-gray-500 hover:text-gray-800">
                                        <vue-excel-xlsx :data="convertToXlsxData.data"
                                                        :columns="convertToXlsxData.columns"
                                                        :filename="convertToXlsxData.fileName"
                                                        :sheetname="convertToXlsxData.sheetName">
                                            <i class="fal fa-download mr-2"/>Download Excel
                                        </vue-excel-xlsx>
                                    </div>


                                    <div v-if="selected.length > 0" class="flex items-center flex-shrink-0 mt-4">
                                        <autralis-delete-group-comp :group-ids="businessUnitGroupsIds"
                                                                    :company-id="$props.companyId"
                                                                    :page="page"
                                                                    :itemsPerPage="itemsPerPage"
                                                                    @on-success="selected = [];selectAll = false;savedFlag=true"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- FILTERS -->
                        <div class="flex mt-4">
                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="false" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer font-medium rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>
                    </div>


                    <!-- NORMAL SCREENS (>=1245px and <1450) -->
                    <div class="business-units-header-base flex flex-col w-full">
                        <div class="flex w-full">
                            <!-- BUTTON ADD NEW -->
                            <div class="border-r border-gray-200 pr-4 mr-4">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus mr-1"/><span>Add</span>
                                </button>
                            </div>

                            <!-- OTHER BUTTONS DROPDOWN -->
                            <div class="flex">
                                <div class="flex items-center">
                                    <div v-if="businessUnitGroups && businessUnitGroups.length > 0" class="flex cursor-pointer text-gray-500 hover:text-gray-800">
                                        <vue-excel-xlsx :data="convertToXlsxData.data"
                                                        :columns="convertToXlsxData.columns"
                                                        :filename="convertToXlsxData.fileName"
                                                        :sheetname="convertToXlsxData.sheetName">
                                            <i class="fal fa-download mr-2"/>Download Excel
                                        </vue-excel-xlsx>
                                    </div>
                                </div>


                                <div v-if="selected.length > 0" class="flex items-center flex-shrink-0 border-l border-gray-200 pl-4 ml-4">
                                    <autralis-delete-group-comp :group-ids="businessUnitGroupsIds"
                                                                :company-id="$props.companyId"
                                                                :page="page"
                                                                :itemsPerPage="itemsPerPage"
                                                                @on-success="selected = [];selectAll = false;savedFlag=true"/>
                                </div>
                            </div>
                        </div>

                        <!-- FILTERS -->
                        <div class="flex mt-4">
                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="false" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer font-medium rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>
                    </div>


                    <!-- BIG SCREENS (>=1450px) -->

                    <div class="business-units-header-lg flex justify-between w-full">
                        <div class="flex flex-1">
                            <!-- ADD NEW BUTTON -->
                            <div class="border-r border-gray-200 pr-4 mr-4">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus mr-0 md:mr-1"/>
                                    <span class="hidden md:block">Add</span>
                                </button>
                            </div>
                            <!-- OTHER BUTTONS -->
                            <div class="flex">
                                <div class="flex items-center">
                                    <div v-if="businessUnitGroups && businessUnitGroups.length > 0" class="flex cursor-pointer text-gray-500 hover:text-gray-800">
                                        <vue-excel-xlsx :data="convertToXlsxData.data"
                                                        :columns="convertToXlsxData.columns"
                                                        :filename="convertToXlsxData.fileName"
                                                        :sheetname="convertToXlsxData.sheetName">
                                            <i class="fal fa-download mr-2"/>Download Excel
                                        </vue-excel-xlsx>
                                    </div>
                                </div>


                                <div v-if="selected.length > 0" class="flex items-center flex-shrink-0 border-l border-gray-200 pl-4 ml-4">
                                    <autralis-delete-group-comp :group-ids="businessUnitGroupsIds"
                                                                :company-id="$props.companyId"
                                                                :page="page"
                                                                :itemsPerPage="itemsPerPage"
                                                                @on-success="selected = [];selectAll = false;savedFlag=true"/>
                                </div>
                            </div>
                        </div>


                        <!-- FILTERS -->
                        <div class="flex">

                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="true" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-gray-50 md:bg-white flex flex-1 overflow-y-auto mb-6">
                <div class="align-middle inline-block min-w-full overflow-hidden px-6">

                    <!--                <autralis-loading-table-comp v-if="fetchingGroups.loading"/>-->

                    <div class="z-0 overflow-x-auto bg-white rounded-lg overflow-y-auto relative border border-gray-200">
                        <table class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                            <tr class="text-left">
                                <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-white" style="width: 20px">
                                    <label class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                        <input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" v-model="selectAll">
                                    </label>
                                </th>
                                <th class="bg-white sticky top-0 border-b border-gray-200 px-2 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">Edit</th>
                                <th v-for="(col, index) in columns"
                                    :key="'head-' + col.id + '-' + index"
                                    :style="{minWidth: col.width + 'px' }"
                                    class="bg-white sticky top-0 border-b border-gray-200 px-2 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">{{ col.title }}
                                </th>
                            </tr>
                            </thead>


                            <!-- ERROR -->
                            <tbody v-if="!fetchingGroups.loading && fetchingGroups.error">
                            <tr>
                                <td :colspan="Object.keys(columns).length + 2">
                                    <div class="flex rounded-md bg-red-50 p-4 items-center m-6">
                                        <i class="fal fa-exclamation-circle text-red-400 mr-3"/>
                                        <h3 class="text-sm font-medium text-red-800">{{ fetchingGroups.error }}</h3>
                                    </div>
                                </td>
                            </tr>
                            </tbody>

                            <!-- NO RESULTS -->
                            <tbody v-else-if="!fetchingGroups.loading && !fetchingGroups.error && businessUnitGroups.length < 1">
                            <tr>
                                <td :colspan="Object.keys(columns).length + 2">
                                    <div class="flex flex-row justify-center items-center p-2 font-bold text-2xl">No records found</div>
                                </td>
                            </tr>
                            </tbody>

                            <!-- RESULTS -->
                            <tbody v-else>
                            <tr v-for="(item, index) in businessUnitGroups"
                                :key="item.id + '-' + index"
                                :class="[
                                'text-gray-500 hover:bg-blue-500 hover:text-white cursor-pointer',
                                 selected.includes(item.id) && 'bg-blue-50',
                                 currentlyActive === item.id && 'bg-blue-500 text-white'
                                 ]">
                                <td class="border-dashed border-t border-gray-200 px-3" style="width: 20px">
                                    <label class="inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                        <input type="checkbox"
                                               class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                               :checked="selected.includes(item.id)"
                                               @click.stop="handleSelectCheckbox(item.id)">
                                    </label>
                                </td>
                                <td class="border-dashed border-t border-gray-200 px-3 cursor-pointer underline"
                                    style="min-width: 60px"
                                    @click="edit(item)">
                                    Edit
                                </td>

                                <td v-for="(col, index) in columns"
                                    :key="col.id + '-' + index"
                                    :style="{minWidth: col.width + 'px' }"
                                    class="border-dashed border-t border-gray-200 select-none"
                                    @click.stop="handleClick(item, $event)">
                                    <component :is="col.component" :column="col.id" :subColumn="col.subColumn" :item="item" @select-item="select"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>

            <div v-if="!fetchingGroups.loading && $store.getters['master/totalBusinessUnitGroups'] > itemsPerPage"
                 class="px-6 py-2 w-full h-14 static bottom-0 bg-gray-50 text-gray-800 left-0 right-0">
                <autralis-paginator-comp
                    :currentPage="page"
                    :recordsPerPage="itemsPerPage"
                    :totalCount="$store.getters['master/totalBusinessUnitGroups']"
                    @on-change-page="onPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>

import {DETAIL_PANEL_KEYS, HOME_LIST_ITEMS_PER_PAGE} from "@/master/constants";
import {modalInfo} from "@/common/modal";
import comboButton from "@/components/combo-button";
import BaseCol from "@/master/views/columns/base-column";
import BaseColCount from "@/master/views/columns/base-column-count";
import SuccessBox from "@/components/success-box";
import ErrorBox from "@/components/error-box";
import Paginator from "@/master/views/Home/company/components/Paginator";
import Modal from "@/components/modal";
import DeleteGroup from "@/master/views/Home/company/components/DeleteGroup";
import LoadingTable from "../../../../components/loading-table";
import {every, filter, find, isArray, isBoolean, isEmpty, isInteger, isPlainObject, map, orderBy, sortBy} from "lodash";
import {handleFetchGroups} from "@/master/views/Home/company/handlers";

const defaultColumns = {
    name: {width: 150, id: 'name', title: 'Name', component: 'autralis-base-col-comp', defaultSort: true},
    capacity: {width: 150, id: 'capacity', title: 'Capacity', component: 'autralis-base-col-comp', defaultSort: false},
    businessUnits: {width: 150, id: 'businessUnits', title: 'Total business units', component: 'autralis-base-count-comp', defaultSort: false},
};
export default {
    name: "BusinessUnitGroups",
    props: {
        companyId: {type: Number}
    },
    data() {
        return {
            columns: defaultColumns,
            savedFlag: null,
            sortItem: null,
            selectAll: false,
            sortFlag: false,
            tableHeight: window.innerHeight - 206,
            selectedId: null,
            selected: [],
            fetchingGroups: {
                loading: true,
                error: null
            },
            error: null,
            sort: null,
            //Pagination
            page: 1,
            itemsPerPage: HOME_LIST_ITEMS_PER_PAGE,
            deleting: false,
            modal: modalInfo(),
            numClicks: 0,
            clickTimer: null,
            currentlyActive: null,
            menuOpen: false,
            headerTop: 0
        }
    },

    watch: {
        selectAll(val) {
            this.selected = val ? this.businessUnitGroups.map(i => i.id) : []
        },
        sort() {
            this.updateBusinessUnitGroups(this.$store.getters['master/businessUnitGroups'])
        },

        '$store.state.master.detail': function () {
            const storeDetail = this.$store.state.master.detail;
            if (storeDetail) {
                if (storeDetail['key'] && storeDetail['key'] === DETAIL_PANEL_KEYS.EDIT_GROUP) {
                    this.currentlyActive = storeDetail['id']
                }
            } else {
                this.currentlyActive = null
            }
        },
        companyId(newCompanyId) {
            this.fetchGroups(newCompanyId);
        }
    },

    computed: {
        businessUnitGroups() {
            return this.sortedItems(this.$store.getters['master/businessUnitGroups']);
        },

        businessUnitGroupsIds() {
            const selectedBusinessUnitGroups = filter(this.businessUnitGroups, bu => this.selected.includes(bu.id))
            return map(selectedBusinessUnitGroups, bu => bu.id)
        },

        convertToXlsxData() {
            let fileName = 'business-unit-groups';

            const sheetName = 'business-unit-groups'
            const columns = [
                {label: "Name", field: "name"},
                {label: "Capacity", field: "capacity"},
                {label: "Total business units", field: "totalBusinessUnits"}
            ]

            const ordered = orderBy(this.$store.getters['master/businessUnitGroups'], ['name'], ['asc'])
            const mappedData = map(ordered, group => {
                return {
                    'name': group.name,
                    'capacity': group.capacity,
                    'totalBusinessUnits': group.businessUnits.length
                }
            })

            return {
                columns: columns,
                data: mappedData,
                fileName,
                sheetName
            }
        }
    },

    methods: {
        onResize() {
            if (window.innerWidth < 1024) {
                this.headerTop = 157;
            } else {
                this.headerTop = 0
            }
        },
        setInitSort() {
            const item = find(this.selectColumns(), col => col.defaultSort);
            if (item) this.selectSort(item);
        },

        sortedItems(items) {
            let results = items;

            if (!this.sort) return results;

            if (isBoolean(items[this.sort.field]) || isInteger(items[this.sort.field])) {
                results = sortBy(items, [this.sort.field])
            } else if (every(items, item => isPlainObject(item[this.sort.field]))) {
                results = orderBy(items, [item => item[this.sort.field][this.sort.subField]], [this.sort.type])
            } else if (every(items, item => isArray(item[this.sort.field]))) {
                results = orderBy(items, [item => item[this.sort.field].length], [this.sort.type])
            } else {
                results = orderBy(items, [this.sort.field], [this.sort.type])
            }
            return results;
        },

        selectColumns() {
            return Object.keys(this.columns).map(c => {
                return {
                    item: c,
                    name: this.columns[c].title,
                    key: this.columns[c].id,
                    subKey: this.columns[c].subColumn,
                    counter: null,
                    icon: null,
                    separator: false,
                    defaultSort: this.columns[c].defaultSort
                }
            });
        },

        handleSelectCheckbox(id) {
            let newSelected = null;
            if (find(this.selected, item => item === id)) {
                newSelected = filter(this.selected, item => item !== id);
            } else {
                newSelected = [...this.selected, id]
            }
            this.selected = newSelected
        },

        edit(item) {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_GROUP,
                companyId: this.$props.companyId,
                groupId: item.id,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        singleRowClick(item) {
            this.handleSelectCheckbox(item.id)
        },
        doubleRowClick(item) {
            this.edit(item);
        },

        handleClick(item, e) {
            e.preventDefault()
            this.numClicks++;

            if (this.numClicks === 1) {
                this.clickTimer = setTimeout(() => {
                    this.numClicks = 0;
                    this.singleRowClick(item, e);
                }, 200);
            } else if (this.numClicks === 2) {
                clearTimeout(this.clickTimer);
                this.numClicks = 0;
                this.doubleRowClick(item, e);
            }
        },

        addNew() {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_GROUP,
                companyId: this.$props.companyId,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        select(item) {
            console.log("SELECT");
            console.log(item);
            // this.$emit('select-item', item)
            // this.$store.commit('seller/openItem', 0);
        },

        setSortType() {
            this.sort = {...this.sort, type: this.sort.type === 'asc' ? "desc" : "asc"}
        },

        selectSort(item) {
            this.sort = {field: item.key, subField: item.subKey, title: item.name, type: 'asc'}
        },

        updateBusinessUnitGroups(items) {
            this.$store.commit('master/setBusinessUnitGroups', this.sortedItems(items))
        },

        onPageChange(page) {
            this.page = page;
            this.fetchGroups(this.$props.companyId)
        },

        setOrder(field) {
            const existing_order = !isEmpty(this.sort);
            if (existing_order) {
                // Change type (ASC or DESC)
                this.sort = {
                    ...this.sort,
                    type: this.sort.type === 'asc' ? 'desc' : 'asc'
                }
            } else {
                // Add new order
                this.sort = {
                    field: field,
                    type: 'desc'
                }
            }
        },

        async fetchGroups(companyId) {
            const payload = {
                companyId: companyId,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }

            this.fetchingGroups.loading = true;
            this.fetchingGroups.error = null;

            await handleFetchGroups(payload, (response) => {
                const {groups, totalCount} = response;
                this.$store.commit('master/setTotalBusinessUnitGroups', totalCount)
                this.$store.commit('master/setBusinessUnitGroups', groups)

            }, (error) => {
                this.fetchingGroups.error = error
            })

            this.fetchingGroups.loading = false;
        },
    },

    components: {
        'autralis-combo-button-comp': comboButton,
        'autralis-base-col-comp': BaseCol,
        'autralis-base-count-comp': BaseColCount,
        'autralis-success-comp': SuccessBox,
        'autralis-error-comp': ErrorBox,
        'autralis-paginator-comp': Paginator,
        'autralis-modal-comp': Modal,
        'autralis-delete-group-comp': DeleteGroup,
        'autralis-loading-table-comp': LoadingTable,
    },

    created() {
        window.addEventListener("resize", this.onResize);
        this.setInitSort();
        this.fetchGroups(this.$props.companyId);
        this.onResize();
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
}
</script>

<style scoped>
.dropdown-nav {
    top: 120%;
    min-width: 150px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.business-units-header-sm, .business-units-header-base, .business-units-header-lg {
    display: none;
}

@media (max-width: 1245px) {
    .business-units-header-sm {
        display: flex;
    }
}

@media (min-width: 1245px) and (max-width: 1450px) {
    .business-units-header-base {
        display: flex;
    }
}

@media (min-width: 1450px) {
    .business-units-header-lg {
        display: flex;
    }
}

</style>